import { useState } from 'react'

const initialState = {
  name: '',
  email: '',
  message: '',
}


export const Contact = (props) => {
  const [{ name, email, message }, setEmailData] = useState(initialState)

  const [ mailSent, setMailSent] = useState(false);
  const [ mailError, setMailError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target
    setEmailData((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setEmailData({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    fetch('https://seaweedheroes.com/email.php', {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
        message
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
      })
      .then(result => {
        console.log(result);
        setMailSent(result.ok);
      })
      .catch(error => {
        console.log(error);
        setMailError(`${error.message} There was a error sending the message, please email it to info@seaweedheroes.com`)
      }
    );

    console.log(name, email, message)
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
            <div className='section-title'>
              <h2>Contact Us</h2>
            </div>
              { mailSent ? <h3>Thank you for contacting us.</h3> :
                <div>
                  <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <input
                            type='text'
                            id='name'
                            name='name'
                            className='form-control'
                            placeholder='Name'
                            required
                            onChange={handleChange}
                          />
                          <p className='help-block text-danger'></p>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <input
                            type='email'
                            id='email'
                            name='email'
                            className='form-control'
                            placeholder='Email'
                            required
                            onChange={handleChange}
                          />
                          <p className='help-block text-danger'></p>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <textarea
                        name='message'
                        id='message'
                        className='form-control'
                        rows='4'
                        placeholder='Message'
                        required
                        onChange={handleChange}
                      ></textarea>
                      <p className='help-block text-danger'></p>
                    </div>
                    <div id='success'></div>
                    { mailError ? <div class="alert alert-danger alert-dismissible" role="alert">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <strong>Error!</strong> {`${mailError}`}
                                  </div> 
                                  : null
                    }
                    <button type='submit' className='btn btn-custom btn-lg'>
                      Send Message
                    </button>
                  </form>
                </div>
              }
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            {/* <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div> */}
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  {/* <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li> */}
                  <li>
                    <a href={props.data ? props.data.youtube : '/'} target="_blank" rel="noreferrer">
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
          &copy; 2022 Seaweed Heroes
          </p>
        </div>
      </div>
    </div>
  )
}
