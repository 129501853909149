export const Download = () => {
    return (
      <div id='download' className='text-center'>
        <div className='container'>
          <div className='col-md-8 col-md-offset-2 section-title'>
            <h2>Download</h2>
            <p>We will be adding a link to download the SWH prototype here soon!</p>
            <p>Please <a href='#contact' className='page-scroll'>
                CONTACT
              </a> us if you want to be notified when we do!</p>
          </div>
          <div id='row'>
          <div>
            <img src="img/monsterfish.png" className="img-responsive col-md-4" alt="" />
            <img src="img/egg.png" className="img-responsive col-md-4" alt="" />
            <img src="img/droid.png" className="img-responsive col-md-4" alt="" />
          </div>
          {/* <div id='row'>
            <div className='col-md-12'>
                <a href="#download"><img className="chest" alt="" /></a>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    )
  }
  