import { Image } from "./image";

export const Gallery = (props) => {

  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Videos</h2>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={ `${d.title}-${i}` } className='col-md-6'>
                  <div type="button" data-toggle="modal" data-target={ `#${i}` }>
                    <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                  </div>
                  <div className="modal fade bd-example-modal-lg"id={ `${i}` } tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title" id={ d.title }>{ d.title }</h4>
                          <button type="button" style={{ position: 'relative', top: -70, left: -10 }} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                        { d.largeImage ?
                            <img
                              src={ d.largeImage }
                              className='img-responsive'
                              alt={ d.title }
                            />
                          :
                            null
                          }
                            <div style={{ padding: 10} } dangerouslySetInnerHTML={{__html: d.description}} />
                          { d.video ?
                              <div className="video-container">
                                <iframe className="iframed" title={ d.title } width="1920" height="1080" src={d.video} frameBorder="0" allowFullScreen></iframe>
                              </div>
                          :
                            null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}


